*[draggable].gs-selected-node:not(a.gs-selected-node) {
  cursor: move !important;
}

*[contenteditable="true"] {
  cursor: text !important;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.gs-editor-pane .gs-editor-node:hover:not(.gs-selected-node) {
  outline: 1px dashed #dee2e6;
}

.gs-selected-node,
.gs-selected-node:focus {
  box-shadow: inset 0 0 0.24rem 0.12rem rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  outline: 1px dashed rgba(0, 0, 255, 1);
  outline-offset: -0.12rem;
}

.gs-editor-pane .gs-selected-drop-target {
  outline: 1px dashed #dee2e6;
  background-color: #f9fced !important;
}

/* Show grids on drop target and all childs*/
.gs-drop-target,
.gs-drop-target > .gs-editor-node {
  outline: 1px dashed #dee2e6;
}

.gs-editor-pane .gs-selected-drop-target > .gs-editor-node {
  outline: 1px dashed #dee2e6;
}

.gs-editor-pane .gs-selected-node > .gs-editor-node {
  outline: 1px dashed #dee2e6;
}

.h-90 {
  height: 90% !important;
}

.w-90 {
  width: 90% !important;
}






