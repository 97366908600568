.link-dark {
  color: var(--dark);
}

.link-primary {
  color: var(--primary);
}

.link-secondary {
  color: var(--secondary);
}

.link-success {
  color: var(--success);
}

.link-danger {
  color: var(--danger);
}

.link-warning {
  color: var(--warning);
}

.link-info {
  color: var(--info);
}

.link-light {
  color: var(--light);
}

.link-muted {
  color: var(--muted);
}

.link-white {
  color: var(--white);
}
